import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/layouts/default.js";
import { Section } from 'src/components/molecules/Section';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <Section mdxType="Section">
      <p>{`If you believe you have found a security vulnerability on one of our websites or projects, we encourage you to let us know right away. We will investigate all legitimate reports and do our best to fix the problem quickly. Also, let us know if you would like credit for discovering the issue. We can cite you as the discoverer if we weren't previously aware of the problem.`}</p>
      <p>{`Before reporting, please review this page; If you are looking to report another type of issue, please use `}<a parentName="p" {...{
          "href": "https://rasa.com/contact/"
        }}>{`https://rasa.com/contact/`}</a>{` for assistance.`}</p>
      <p>{`If you comply with the policies below when reporting a security issue to us, we will not initiate a lawsuit or law enforcement investigation against you in response to your report. We ask that:`}</p>
      <ul>
        <li parentName="ul">{`You give us reasonable time to investigate and mitigate an issue you report before making public any information about the report or sharing such information with others. (90 days)`}</li>
        <li parentName="ul">{`You do not interact with an individual account (including modifying or accessing data from the account) if the account owner has not consented to such actions.`}</li>
        <li parentName="ul">{`You make a good faith effort to avoid privacy violations and disruptions to others, including (but not limited to) destruction of data and interruption or degradation of our services.`}</li>
        <li parentName="ul">{`You do not exploit a security issue you discover for any reason. (This includes demonstrating additional risk, such as attempted compromise of sensitive company data or probing for further issues)`}</li>
        <li parentName="ul">{`You do not violate any other applicable laws or regulations.`}</li>
      </ul>
      <p>{`We do not offer a bug bounty program.`}</p>
      <p><strong parentName="p">{`Scope`}</strong></p>
      <ul>
        <li parentName="ul">{`rasa.com domain and subdomains`}</li>
        <li parentName="ul">{`l3-ai.dev domain and subdomains`}</li>
        <li parentName="ul">{`Rasa X product`}</li>
        <li parentName="ul">{`All Rasa open source projects (github.com/RasaHQ)`}</li>
      </ul>
      <p><strong parentName="p">{`Out of Scope`}</strong></p>
      <ul>
        <li parentName="ul">{`Denial-of-service attacks.`}</li>
        <li parentName="ul">{`Reports indicating that our services do not fully align with "best practice", e.g. missing security headers (CSP, x-frame-options, x-prevent-xss etc) or suboptimal email related configuration (SPF, DMARC etc)`}</li>
        <li parentName="ul">{`TLS configuration weaknesses (e.g. "weak" cypher suite support, TLS1.0 support, sweet32 etc.)`}</li>
        <li parentName="ul">{`Social engineering or phishing of Rasa employees or contractors`}</li>
        <li parentName="ul">{`Any attacks against Rasa's physical property`}</li>
        <li parentName="ul">{`Any form of credentials brute-forcing is strictly forbidden`}</li>
        <li parentName="ul">{`Any services hosted by 3rd party providers and services`}</li>
      </ul>
      <p><strong parentName="p">{`Contact Information`}</strong></p>
      <ul>
        <li parentName="ul">{`Email `}<a parentName="li" {...{
            "href": "mailto:security@rasa.com"
          }}>{`security@rasa.com`}</a></li>
      </ul>
      <p>{`Please provide:`}</p>
      <ul>
        <li parentName="ul">{`Description of the issue`}</li>
        <li parentName="ul">{`When you found the issue`}</li>
        <li parentName="ul">{`How to reproduce it`}</li>
        <li parentName="ul">{`Any thoughts on how we can mitigate the issue`}</li>
        <li parentName="ul">{`Feel free to share scripts or network traces`}</li>
      </ul>
      <p>{`Last updated: 22.06.2021`}</p>
    </Section>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      